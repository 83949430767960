<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <div class="" style="text-align: center; margin-bottom: 18px"></div>
            <v-card class="elevation-12 pa-8" v-if="success">
              <v-card-text>
                <div class="d-block">
                  <div class="d-block text-center">
                    <img
                      src="../assets/logo.svg"
                      alt=""
                      class="logo-forms mr-auto my-auto"
                    />
                  </div>
                  <div class="mt-6 mb-8 text-center">
                    <p>
                      Vstupte do moderního domu jednadvacátého
                      století.
                    </p>
                  </div>
                </div>
                <div class="mb-14 text-center font-weight-bold">
                  Na Váš e-mail jsme odeslali odkaz, kde si budete moci změnit
                  své heslo.
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  type="submit"
                  color="primary"
                  class="mt-12"
                  style="width: 100%"
                  tile
                  @click="$router.push('/login')"
                  >Hotovo</v-btn
                >
              </v-card-actions>
              <!-- <div style="text-align: center" class="mt-8">
                <button
                  type="button"
                  @keydown.enter.prevent="() => {}"
                  @click.stop="
                    () =>
                      $router
                        .push({
                          name: 'Login',
                        })
                        .catch(() => {})
                  "
                  class="mymd-primary-dark-text"
                  style="font-size: 12px; z-index: 999"
                >
                  Přihlásit se
                </button>
              </div> -->
            </v-card>
            <v-card class="elevation-12 pa-8" v-if="!success">
              <v-card-text>
                <div class="d-block">
                  <div class="d-block text-center">
                    <img
                      src="../assets/logo.svg"
                      alt=""
                      class="logo-forms mr-auto my-auto"
                    />
                  </div>
                  <div class="mt-6 mb-8 text-center">
                    <p>
                      Vstupte do moderního domu jednadvacátého
                      století.
                    </p>
                  </div>
                </div>
                <v-form
                  @submit.prevent="forgottenPassword"
                  @keyup.native.enter="forgottenPassword"
                >
                  <Spinner v-if="processing" />
                  <v-text-field
                    ref="email"
                    label="e-mail"
                    name="login"
                    type="text"
                    v-model="$v.email.$model"
                  ></v-text-field>
                  <span
                    class="error-input"
                    :class="{
                      'error-input--active': $v.email.$anyError,
                    }"
                  >
                    {{
                      !$v.email.required
                        ? "Toto pole je povinné"
                        : "Neplatný email"
                    }}
                  </span>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  type="submit"
                  color="primary"
                  class="mt-12"
                  style="width: 100%"
                  tile
                  @click="forgottenPassword"
                  >Odeslat</v-btn
                >
              </v-card-actions>
              <div style="text-align: center" class="mt-8">
                <button
                  type="button"
                  @keydown.enter.prevent="() => {}"
                  @click.stop="
                    () =>
                      $router
                        .push({
                          name: 'Login',
                        })
                        .catch(() => {})
                  "
                  class="mymd-primary-dark-text"
                  style="font-size: 12px; z-index: 999"
                >
                  Přihlásit se
                </button>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
/* eslint-disable */
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import Auth from "../api/authService";
import Spinner from "../components/Spinner";
import bus from "../plugins/bus";
export default {
  mixins: [validationMixin],

  validations: {
    email: { required, email },
  },
  name: "ForgottenPassword",
  components: {
    Spinner,
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
  },
  data() {
    return {
      email: null,
      processing: false,
      success: false,
      message: "",
    };
  },
  methods: {
    async forgottenPassword() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.processing = true;
        const r = await Auth.forgottenPassword(this.email);
        this.message = r.message;
        this.processing = false;
        this.success = true;
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit("snackbar", {
            text: error.response.data.error.message,
            color: "error",
          });
        }
        this.processing = false;
      }
    },
  },
};
</script>
